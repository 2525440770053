var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app flex-row align-items-center" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c(
          "b-row",
          { staticClass: "justify-content-center" },
          [
            _c("b-col", { attrs: { md: "6" } }, [
              _c("div", { staticClass: "clearfix" }, [
                _c("h1", { staticClass: "float-left display-3 mr-4" }, [
                  _vm._v("Auth/Callback")
                ]),
                _c("h4", { staticClass: "pt-3" }, [_vm._v("Logging you in!")]),
                _c("p", { staticClass: "text-muted" }, [
                  _vm._v("Any minute now...")
                ])
              ])
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }